<template>
  <div class="DateInput">
    <label :for="id">{{ label }}</label>
      <Datepicker
        :id="id"
        :class="{ 'whiteBG': whiteBg }"
        @update:modelValue="$emit('date-selected', $event )"
        v-model="value"
        format="dd-MM-yyyy"
        locale="es"
        :flow="['year','month','day']"
        :year-range="yearRange"></Datepicker>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  name: 'TextInput',
  components: {
    Datepicker
  },
  props: {
    label       : String,
    value       : [String, Date],
    id          : String,
    whiteBg     : Boolean,
    yearRange   : {
      type: Array,
      default: [1900, 2100]
    }
  }
}
</script>

<style lang="scss" scoped>
  .DateInput {
    padding           : .5rem;
    display           : flex;
    flex-direction    : column;
    width             : 50%;

    @media screen and ( max-width: 768px ) {
      width           : 100%;
    }

    label {
      text-transform  : uppercase;
      font-weight     : 600;
      font-size       : .8rem;
      padding-bottom  : 5px;
    }
  }
</style>

<style lang="scss">
  .whiteBG {
    input.mx-input {
      background    : white;
    }
  }

  .mx-datepicker, .mx-input-wrapper, .mx-input {
    width: 100%;
  }

  input.mx-input {
    border          : 1px solid #e4e4e4;
    font-size       : 1.2rem;
    padding         : .5rem 1rem;
    width           : 100%;
    height          : auto;
    line-height     : 1rem;
    color           : black;
    border-radius   : 0;
    background      : #f4f4f4;

    &:disabled {
      cursor        : not-allowed;
    }

    &:focus {
      outline       : none;
    }
  }
</style>
